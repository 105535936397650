.snackbar-android .MuiSnackbarContent-root {
    background-color: #1ec100!important;
}
.snackbar-android .MuiButton-root {
    padding: 10px;
}
.snackbar-ios .MuiSnackbarContent-root {
    background-color: #f2f8ff!important;
    color: #5e6061;
}
@media only screen and (min-width: 599px) {
    #bglogin {
        background-size: cover!important;
        top: -40px !important;
    }
}